import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 720.000000 714.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,714.000000) scale(0.100000,-0.100000)">
     

<path d="M3662 5667 c-17 -8 -49 -63 -112 -191 -212 -438 -240 -495 -240 -502
0 -4 8 3 19 15 16 18 20 19 25 6 3 -10 11 -13 18 -9 7 4 10 4 6 -1 -10 -10 21
-34 45 -35 21 0 55 32 49 48 -2 7 4 15 13 18 19 8 48 72 40 91 -2 8 -1 11 4 8
5 -3 12 4 16 15 7 23 35 27 35 6 0 -39 20 -16 70 80 30 57 60 104 66 104 6 0
21 -21 34 -47 98 -198 165 -312 176 -302 7 6 10 17 8 24 -3 7 -1 22 5 33 8 15
7 23 -3 31 -12 10 -12 16 1 42 8 16 17 42 19 57 3 15 6 34 7 44 2 9 9 23 16
31 10 13 2 37 -45 139 -93 200 -133 276 -157 293 -25 17 -81 19 -115 2z m-159
-587 c3 -11 1 -18 -4 -14 -5 3 -9 12 -9 20 0 20 7 17 13 -6z"/>
<path d="M3297 4954 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M3381 4948 c-1 -22 18 -33 32 -19 8 8 7 11 -5 11 -9 0 -19 6 -21 13
-3 7 -6 6 -6 -5z"/>
<path d="M3262 4880 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z"/>
<path d="M3222 4804 c-12 -24 -18 -44 -13 -44 5 0 14 14 21 30 7 17 19 34 27
39 13 7 13 9 1 14 -10 3 -22 -9 -36 -39z"/>
<path d="M3233 4720 c-10 -14 -10 -21 -1 -26 7 -5 3 -10 -12 -16 -17 -6 -20
-12 -12 -20 8 -8 7 -17 -5 -34 -14 -22 -14 -24 1 -24 10 0 15 4 12 9 -3 5 1
14 9 21 12 10 15 9 15 -4 0 -9 4 -16 9 -16 10 0 9 19 -2 37 -4 6 -3 14 3 18 6
4 7 12 1 22 -4 9 -6 24 -4 34 7 24 1 24 -14 -1z"/>
<path d="M3207 4579 c-16 -9 -15 -13 13 -47 16 -20 30 -43 30 -50 0 -7 5 -10
11 -7 7 5 5 15 -5 30 -12 19 -14 30 -5 52 5 15 7 24 3 20 -4 -4 -12 -3 -18 2
-7 6 -18 6 -29 0z"/>
<path d="M3225 4480 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3227 4439 c-3 -13 -1 -19 6 -16 15 5 22 37 9 37 -5 0 -12 -9 -15
-21z"/>
<path d="M2760 3877 c0 -5 5 -17 11 -28 8 -16 7 -25 -7 -40 -14 -16 -15 -22
-5 -28 9 -6 8 -9 -6 -13 -16 -4 -49 -76 -46 -98 0 -3 4 -20 8 -39 6 -26 4 -32
-6 -26 -9 6 -11 3 -6 -8 4 -11 1 -17 -9 -17 -9 0 -12 5 -8 12 4 7 3 8 -5 4
-14 -9 -5 -26 14 -26 12 0 12 -5 -1 -35 -7 -19 -14 -37 -14 -40 0 -7 359 -6
387 1 24 7 35 43 20 70 -5 10 -10 31 -9 48 0 17 -5 34 -11 39 -9 7 -9 10 1 14
33 13 4 21 -70 20 l-83 -2 3 -47 c2 -31 -1 -48 -8 -48 -7 0 -9 -8 -5 -20 4
-11 2 -20 -4 -20 -6 0 -10 -3 -10 -7 4 -26 -4 -24 -15 4 -8 17 -17 61 -21 97
-4 36 -12 66 -17 66 -5 0 -7 4 -3 9 3 6 -2 14 -11 19 -9 5 -18 28 -21 53 -3
24 -9 46 -13 49 -4 3 -12 14 -18 25 -6 11 -12 16 -12 12z"/>
<path d="M3180 3772 c0 -26 -6 -44 -15 -49 -10 -6 -16 -31 -20 -78 -2 -39 -9
-87 -14 -107 -5 -20 -6 -39 -3 -42 7 -8 149 -5 187 3 24 5 26 8 13 13 -14 6
-18 19 -18 56 0 39 -10 68 -42 133 -24 45 -53 89 -65 97 -22 14 -23 14 -23
-26z"/>
<path d="M3350 3518 c0 -9 5 -20 10 -23 13 -8 13 5 0 25 -8 13 -10 13 -10 -2z"/>
<path d="M2501 3060 c-10 -14 -35 -55 -56 -92 -22 -38 -42 -68 -45 -68 -3 0
-32 41 -64 90 l-58 90 -49 0 c-27 0 -49 -2 -49 -5 0 -3 36 -61 80 -129 l80
-125 -80 -132 c-44 -72 -80 -133 -80 -135 0 -2 22 -4 48 -4 l49 0 49 87 c27
47 55 88 61 90 7 3 38 -36 70 -86 l58 -91 49 0 c34 0 47 4 43 13 -2 6 -39 65
-80 131 -73 113 -76 119 -62 145 14 27 96 163 129 212 l17 27 -47 4 c-40 3
-48 0 -63 -22z"/>
<path d="M3518 3082 l-138 -3 0 -264 0 -265 155 0 155 0 0 35 0 34 -107 3
-108 3 0 80 0 80 93 3 92 3 0 29 0 29 -92 3 -93 3 -3 77 -3 77 108 3 108 3 3
31 c4 40 3 40 -170 36z"/>
<path d="M5115 3081 c-121 -30 -200 -135 -199 -266 0 -108 67 -212 163 -252
58 -25 157 -21 216 10 61 30 86 57 118 122 36 72 42 123 22 192 -29 101 -87
160 -181 189 -50 15 -91 16 -139 5z m137 -90 c62 -27 92 -77 96 -161 3 -53 0
-75 -17 -107 -53 -105 -173 -131 -262 -56 -42 36 -59 80 -59 155 0 83 28 135
90 166 54 27 95 28 152 3z"/>
<path d="M5991 3082 c-8 -3 -11 -83 -11 -268 l0 -264 45 0 45 0 0 115 c0 63 3
115 8 114 4 0 50 -52 102 -115 l95 -114 47 0 c51 0 59 9 31 38 -10 9 -60 65
-111 124 l-92 107 116 130 c63 72 112 131 107 132 -4 0 -26 2 -47 3 -46 4 -43
6 -160 -131 -43 -51 -82 -93 -87 -93 -5 0 -9 49 -9 109 l0 109 -34 4 c-19 2
-39 2 -45 0z"/>
<path d="M1250 2814 l0 -265 43 3 42 3 3 103 3 102 28 0 c25 0 36 -11 92 -102
l64 -103 48 -3 c26 -2 47 0 47 3 0 3 -29 50 -65 105 -36 55 -65 101 -65 104 0
2 13 7 29 11 17 3 46 24 65 46 33 35 36 45 36 97 0 73 -25 116 -83 142 -34 16
-65 20 -164 20 l-123 0 0 -266z m229 185 c38 -13 51 -35 51 -87 0 -59 -30 -81
-119 -89 l-71 -6 0 97 0 96 54 0 c29 0 67 -5 85 -11z"/>
<path d="M1863 3072 c-6 -4 -48 -106 -93 -227 -44 -121 -88 -237 -96 -258
l-14 -38 47 3 47 3 21 55 21 55 109 0 110 0 22 -55 c21 -53 23 -55 62 -58 29
-2 41 1 41 10 0 13 -25 83 -139 392 -22 60 -46 113 -53 117 -16 11 -69 11 -85
1z m57 -128 c0 -8 16 -56 35 -106 20 -50 34 -93 31 -95 -2 -2 -39 -3 -83 -1
l-80 3 40 112 c21 62 43 110 48 107 5 -3 9 -12 9 -20z"/>
<path d="M2860 2815 l0 -265 45 0 45 0 2 188 3 187 76 -115 c42 -63 97 -148
122 -187 47 -73 47 -73 92 -73 l45 0 0 265 0 265 -45 0 -45 0 0 -187 c0 -223
13 -225 -145 20 l-109 167 -43 0 -43 0 0 -265z"/>
<path d="M3740 3045 l0 -34 73 -3 72 -3 3 -227 2 -228 40 0 40 0 0 230 0 230
70 0 70 0 0 35 0 35 -185 0 -185 0 0 -35z"/>
<path d="M4160 3063 c1 -10 29 -128 63 -263 l62 -245 54 0 54 0 28 105 c16 58
39 141 51 185 11 44 25 84 29 89 4 5 13 -11 19 -35 7 -24 25 -93 42 -154 17
-60 35 -129 41 -153 l11 -43 55 3 54 3 63 255 c35 140 63 258 64 263 0 13 -90
8 -94 -5 -14 -52 -70 -320 -74 -350 -9 -81 -21 -53 -92 217 l-36 140 -50 3
-49 3 -32 -118 c-18 -65 -42 -155 -54 -200 -12 -45 -25 -80 -30 -78 -4 3 -13
29 -19 58 -16 75 -70 331 -70 334 0 2 -20 3 -45 3 -36 0 -45 -3 -45 -17z"/>
<path d="M5532 2818 l3 -263 42 -3 42 -3 3 103 3 103 30 0 c26 0 35 -10 93
-102 l64 -103 44 0 c24 0 44 4 44 8 0 4 -27 49 -60 100 -33 51 -60 96 -60 101
0 4 15 12 33 19 59 21 81 54 85 129 6 84 -14 124 -73 153 -35 17 -61 20 -168
20 l-127 0 2 -262z m245 172 c36 -22 51 -69 34 -112 -14 -37 -43 -50 -120 -52
l-66 -1 -3 79 c-2 44 -1 85 2 93 9 21 114 17 153 -7z"/>
<path d="M5665 2430 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
<path d="M1266 2401 c-30 -34 -17 -63 41 -91 58 -27 71 -60 34 -80 -16 -8 -25
-5 -47 15 -28 26 -47 25 -37 -1 14 -37 105 -48 123 -15 23 45 3 83 -53 96 -34
9 -53 40 -36 61 15 18 43 18 59 -1 16 -19 30 -19 30 0 0 35 -86 48 -114 16z"/>
<path d="M3548 2390 c-28 -29 -30 -35 -26 -87 4 -44 10 -61 29 -76 44 -36 157
-11 159 36 0 14 -27 7 -45 -13 -32 -35 -87 -25 -110 21 -20 38 -19 62 6 93 26
33 86 37 114 6 10 -11 22 -20 27 -20 15 0 8 21 -17 45 -36 37 -97 35 -137 -5z"/>
<path d="M2554 2398 c-13 -21 -4 -141 12 -165 14 -23 64 -33 64 -13 0 6 -9 10
-20 10 -18 0 -20 7 -20 60 0 53 2 60 20 60 11 0 20 5 20 10 0 6 -9 10 -20 10
-13 0 -20 7 -20 20 0 22 -24 27 -36 8z"/>
<path d="M5450 2401 c0 -5 -7 -17 -17 -26 -14 -14 -14 -18 -2 -26 9 -5 16 -32
19 -71 5 -60 6 -63 33 -66 31 -4 37 13 7 21 -17 4 -20 14 -20 61 0 49 2 56 20
56 11 0 20 5 20 10 0 6 -9 10 -20 10 -13 0 -20 7 -20 20 0 11 -4 20 -10 20 -5
0 -10 -4 -10 -9z"/>
<path d="M1603 2365 c-14 -10 -24 -12 -28 -5 -16 27 -25 3 -25 -70 0 -47 4
-80 10 -80 6 0 10 21 10 48 0 78 44 117 83 74 11 -12 17 -36 17 -70 0 -29 5
-52 10 -52 6 0 10 21 10 48 0 53 21 92 50 92 34 0 50 -28 50 -86 0 -30 5 -54
10 -54 11 0 14 108 4 135 -11 27 -51 37 -84 20 -25 -13 -32 -13 -57 0 -35 18
-34 18 -60 0z"/>
<path d="M2023 2365 c-43 -18 -58 -89 -27 -133 17 -25 81 -31 102 -10 10 10
15 10 21 -1 18 -28 31 2 31 69 0 67 -13 97 -30 69 -6 -9 -12 -10 -22 -2 -24
19 -44 21 -75 8z m72 -29 c21 -15 25 -67 5 -90 -15 -19 -61 -21 -78 -4 -19 19
-15 85 6 97 24 14 45 13 67 -3z"/>
<path d="M2819 2369 c-50 -19 -66 -100 -26 -137 32 -30 89 -30 119 0 22 22 22
23 4 26 -11 2 -23 -3 -28 -12 -17 -31 -88 -14 -88 21 0 9 17 13 59 13 33 0 62
4 66 10 13 22 -8 62 -41 76 -18 8 -35 14 -36 13 -2 0 -15 -5 -29 -10z m62 -29
c38 -21 23 -40 -31 -40 -34 0 -50 4 -50 13 0 33 43 47 81 27z"/>
<path d="M3914 2366 c-35 -15 -44 -32 -44 -82 0 -47 29 -74 79 -74 71 0 107
81 59 134 -30 32 -56 38 -94 22z m70 -32 c35 -34 12 -104 -33 -104 -35 0 -54
22 -54 62 0 50 53 76 87 42z"/>
<path d="M4248 2366 c-16 -9 -30 -11 -33 -5 -17 26 -25 1 -25 -72 0 -60 3 -80
13 -77 7 3 13 27 15 58 4 61 15 80 46 80 35 0 46 -20 46 -82 0 -45 3 -58 15
-58 12 0 15 13 15 60 0 51 -4 65 -25 85 -28 29 -31 30 -67 11z"/>
<path d="M4568 2370 c-9 -6 -27 -9 -40 -8 -21 3 -23 -1 -26 -51 -2 -30 -1 -65
3 -78 9 -38 25 -26 25 20 0 55 19 91 50 95 34 5 50 -22 50 -85 0 -40 4 -53 15
-53 23 0 21 111 -2 138 -22 25 -53 34 -75 22z"/>
<path d="M4874 2370 c-53 -21 -69 -88 -33 -134 27 -34 88 -37 121 -4 26 26 7
39 -21 14 -24 -22 -68 -20 -81 3 -16 30 -14 31 49 31 33 0 63 5 66 11 14 22
-6 60 -40 75 -19 8 -36 14 -37 13 -2 0 -13 -4 -24 -9z m64 -32 c26 -26 13 -38
-38 -38 -37 0 -50 4 -50 15 0 29 64 47 88 23z"/>
<path d="M5184 2372 c-55 -8 -79 -97 -40 -141 28 -30 99 -29 121 1 19 28 2 37
-24 14 -38 -34 -91 -9 -91 43 0 30 27 61 53 61 9 0 26 -9 37 -20 19 -19 40
-27 40 -14 0 32 -53 63 -96 56z"/>
<path d="M5908 2369 c-38 -14 -61 -67 -47 -108 13 -37 35 -51 83 -51 66 0 100
83 54 134 -28 30 -55 37 -90 25z m72 -51 c13 -25 13 -31 0 -55 -17 -31 -53
-41 -76 -23 -20 18 -18 86 4 99 27 17 55 8 72 -21z"/>
<path d="M6257 2374 c-3 -4 -21 -7 -39 -8 l-33 -2 -3 -77 c-2 -64 0 -77 13
-77 12 0 15 12 15 58 0 62 11 82 45 82 34 0 45 -20 45 -82 0 -45 3 -58 15 -58
12 0 15 14 15 64 0 58 -3 67 -26 85 -28 22 -37 25 -47 15z"/>
<path d="M2310 2288 c0 -45 4 -78 10 -78 6 0 10 21 10 46 0 55 14 84 40 84 11
0 20 6 20 14 0 9 -12 13 -40 12 l-40 -1 0 -77z"/>
<path d="M3084 2361 c-2 -2 -4 -37 -4 -78 0 -57 3 -73 15 -73 10 0 15 15 17
57 3 52 6 59 31 71 15 7 27 17 27 22 0 9 -77 10 -86 1z"/>
<path d="M5670 2290 c0 -47 4 -80 10 -80 6 0 10 33 10 80 0 47 -4 80 -10 80
-6 0 -10 -33 -10 -80z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
